import React, {useState} from 'react'
import Menu from "./Menu";
import classnames from 'classnames'
import MenuLink from "./MenuLink";


const Header = () => {
    const [show, setShow] = useState(false);const menu = {
        0: {name: 'Как играть', link: "/kak-igrat.html", strict: false, exact: true},
        1: {name: 'Скачать', link: "/download.html", strict: true, exact: false},
        2: {name: 'Контакты', link: "/kontakt.html", strict: true, exact: false},
    };
    const keys = Object.keys(menu);

    return (
        <div className="pos-f-t">
            <nav className="navbar navbar-light">
                <div className="container" style={{
                    display: 'flex',
                }}>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                            aria-expanded="false" aria-label="Toggle navigation"
                            onClick={() => setShow(prevShow=>!prevShow)}>
                        <span className="navbar-toggler-icon"></span>
                        <Menu {...{
                            parent: {
                                className: classnames("dropdown-menu", {show}),
                                "aria-labelledby": "navbarDropdownMenuLink"
                            }, children: {className: "dropdown-item"}
                        }}/>
                    </button>
                    <nav className = "top_new_menu">
                        {
                                    keys.map((i) => {
                                        return (
                                            <MenuLink {...menu[i]} key={i}/>
                                        )
                                    })
                        }
                    </nav>
                </div>
            </nav>
        </div>
    );
};

export default Header