import React from 'react';
import {Helmet} from "react-helmet"
import {BrowserRouter as Router} from "react-router-dom"
import Root from './Root'
import 'bootstrap/scss/bootstrap.scss'


const App = () => (
    <React.Fragment>
        <Helmet title="Да нет онлайн"
                titleTemplate="%s"/>
        <Router>
            <Root/>
        </Router>

    </React.Fragment>
);

export default App