import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const appendRSYa = (id) => {
    const yaScript = document.createElement('script');
    yaScript.setAttribute('type', 'text/javascript');
    yaScript.innerHTML = `window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_`+id+`',
    blockId: '`+id+`'
  })
})`;
    document.body.appendChild(yaScript);
};

const Banner = ({currentPath, id, format}) => {

    useEffect(() => appendRSYa(id), [currentPath]);

    return (
        <div id={"yandex_rtb_" + id}></div>
    )
};

Banner.propTypes = {
    id: PropTypes.string,
};

export default Banner;