import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const MenuLink=({link, name, exact, strict, className})=>{
    return <NavLink to={link} exact={exact} strict={strict} activeClassName='active' className={className}>{name}</NavLink>
};

MenuLink.propTypes = {
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
};

export default MenuLink