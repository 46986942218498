import React, {useState, useEffect} from 'react'
import MenuLink from './MenuLink'

const Menu = (props) => {
    let [menu, setMenu] = useState([
        {name: 'Да нет', link: "/index.html", strict: false, exact: true},
        {name: 'Да нет наверное', link: "/naverno.html", strict: true, exact: false},
        {name: 'Пить не пить', link: "/pit-ne-pit.html", strict: true, exact: false},
        {name: 'Любит не любит', link: "/lubit-ne-lubit.html", strict: true, exact: false},
        {name: 'Повезет не повезет', link: "/povezet-ne-povezet.html", strict: true, exact: false},
        {name: 'Идти не идти ', link: "/idti-ne-idti.html", strict: true, exact: false},
        {name: 'Купить не купить', link: "/kupit-ne-kupit.html", strict: true, exact: false},
        {name: 'Продать не продать', link: "/prodat-ne-prodat.html", strict: true, exact: false},
        {name: 'Простить не простить', link: "/prostit-ne-prostit.html", strict: true, exact: false},
        {name: 'Делать не делать', link: "/delat-ne-delat.html", strict: true, exact: false},
        {name: 'Ехать не ехать', link: "/exat-ne-exat.html", strict: true, exact: false},
        {name: 'Даст не даст', link: "/dast-ne-dast.html", strict: true, exact: false},
    ]);
    const getPages = () => {
        fetch(process.env.PUBLIC_URL + `/pages/new_pages.json`
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                let newMenu = json.map((item) => {
                    return {name: item.title, link: item.link, strict: false, exact: true};
                });
                setMenu([...menu, ...newMenu]);
            });
    };
    useEffect(() => {
            if(props.short)
                setMenu(
                    [
                        {name: 'Да нет', link: "/index.html", strict: false, exact: true},
                        {name: 'Да нет наверное', link: "/naverno.html", strict: true, exact: false}
                    ]
                );
            else getPages();
        }, []
    );

    return (
        <nav {...props.parent}>
            {
                menu.map((item, key) => {
                    return (
                        <MenuLink {...item} {...props.children} key={key} short={props.short}/>
                    )
                })
            }
        </nav>
    )
};

export default Menu