import React, {lazy, Suspense, useState, useEffect} from 'react'
import '../assets/scss/style.scss'
import {Route, Redirect, Switch} from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from "../components/Banner"
import {Link} from 'react-router-dom'

const YesNo = lazy(() => import('./YesNo'));
const YesNoMaybe = lazy(() => import('./YesNoMaybe'));
const StaticPage = lazy(() => import('./StaticPage'));
const NotFound = lazy(() => import('./NotFound'));

const Root = () => {
    let [newPage, setNewPage] = useState([]);
    const getPages = () => {
        fetch(process.env.PUBLIC_URL + `/pages/new_pages.json`
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json()
            })
            .then(function (json) {
                setNewPage(json);
            });
    };
    useEffect(() => {
            getPages();
        }, []
    );
    return <React.Fragment>
        <Header/>
        <div className="container">
            <Switch>
                <Route exact
                       path="/naverno.html"
                       render={() => (
                           <Suspense fallback={<div>Загрузка...</div>}>
                               <YesNoMaybe
                                   title={"Да нет наверное"}
                                   header={"Гадание - Да нет наверное"}
                               />
                           </Suspense>
                       )}
                />
                <Route exact path="/index.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Да Нет онлайн"}
                            meta={"Гадание ДА НЕТ - сайт для онлайн получения ответа на свои вопросы. Просто, быстро и бесплатно."}
                            header={"Виртуальное гадание онлайн"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Простое онлайн гадание: да или нет. Сформулируйте Ваш
                                        вопрос и раскрутите барабан с нужной Вам скоростью, для этого <b>подержите
                                            кнопку в центре</b>, далее получите ответ. Проделав это один или лучше
                                        несколько раз, можно получить более точный ответ в процентах. Корректность
                                        результата зависит от количества произведенных вращений, чем больше - тем
                                        достоверней.</p>
                                    <h2>Правдивое гадание онлайн</h2>
                                    <p className={"description"}>Проведя гадание на нашем сайте Вы можете с полной
                                        уверенностью считать, что оно точное и соответсвует действительности, ведь
                                        результат является случайным и никаким образом не может быть предугадан нами
                                        заранее техническим или математическим способом, ведь Вы сами управляете
                                        движением стрелки. Примерно так же как бросить монетку - ответ в ваших руках. Мы
                                        лишь сделали удобный онлайн инструмент.</p>
                                    <p className={"description"}>Сайт создан для бесплатного гадания онлайн для широкого
                                        круга лиц. Если он Вам понравился, поделитесь им с Вашими друзьями. Так же есть
                                        гадание на три варианта ответа: <b><Link to="/naverno.html">Да нет
                                            наверное</Link></b></p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/pit-ne-pit.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Пить не пить"}
                            meta={"Как решить вопрос - пить или не пить? Бросить монетку или, в нашем случае, крутить рулетку и получить нужный ответ. Гадание происходит с подсчетом результатов."}
                            header={"Пить или не пить"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Извечный вопрос, который возникает при выборе что же
                                        делать - <b>пить или не пить</b>. Провести время в хорошей компании, душевно
                                        побеседовать и, возможно, дальнейшее веселое продолжение будет до самого утра с
                                        разнообразными поездками, клубами, песнями или еще с чем. В любом случае немного
                                        расслабиться еще никому не вредило. Так ведь? Но если подумать, что завтра
                                        ожидают неотложные дела, ранний подъем и возможное похмелье. Как решить данную
                                        дилемму. Вопрос...</p>
                                    <h2>Бросить монетку</h2>
                                    <p className={"description"}>Самым простым способом совладать с сомнениями и принять
                                        быстрое решение, то это бросить монетку и <Link to="/"><b>загадать да или
                                            нет.</b></Link> Но в век цифровой валюты не всегда монетка есть в кармане.
                                        Тут на помощь приходит данная рулетка. Загадайте свой вопрос по поводу
                                        времяпровождения и примите результат достойно. <span style={{color: "#ff0000"}}>Внимание</span>:
                                        Гадать можно несколько раз, если что)</p>
                                    <h3>Результат гадания</h3>
                                    <p className={"description"}>Надеюсь вы сделали правильный выбор и все пройдет без
                                        последствий. Удачи!</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/lubit-ne-lubit.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Любит не любит"}
                            meta={"Погадаем на любит не любит и узнаем стоит ли быть с данным человеком. Онлайн вращения рулетки для получения ответа"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Извечная проблема в отношениях, когда один партнер не
                                        уверен до конца, что второй искренне испытывает глубокие чувства. <b>Гадание на
                                            любит не любит</b> основано исключительно на вашем выборе скорости вращения
                                        данного барабана и результат не возможно предсказать заранее или, тем более,
                                        подстроить его. Совершите не менее 10 вращений и проверьте, что судьба вам
                                        покажет.</p>
                                    <p className={"description"}>Стопроцентный и надежный ответ Вам сможет подсказать
                                        исключительно <b>Ваше сердце</b> и поступки вашей второй половинки. Так что
                                        надеемся полученные результаты будут исключительно радостными и ваше настроение
                                        будет только позитивным. Наверняка по вам скучают и ждут встречи для теплых
                                        объятий и крепких поцелуев. По этому надо <Link to="/idti-ne-idti.html"
                                                                                        target="_blank"><b>идти</b></Link>
                                    </p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/povezet-ne-povezet.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Повезет не повезет"}
                            meta={"Проверить свою удачу в гадании на обычный вопрос - повезет не повезет. Успех в ваших руках."}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Вас постоянно терзают сомнения по поводу вашего
                                        будущего? Вы штудируете свои персональные и зодиакальные гороскопы в надежде на
                                        чудо, но там нет четкого ответа на ваши вопросы? Раскидываете карты таро и ответ
                                        вас не устраивает? Куда податься и как получить быстрый ответ на четко
                                        поставленный вопрос? Все очень просто - сайт <Link to="/"><b>Гадание Да нет</b></Link>
                                        позволяет задать один вопрос и получить необходимый результат быстро и без
                                        всяких сложностей. Крутите барабан удачи!</p>
                                    <h2>Привлечение удачи</h2>
                                    <p className={"description"}>Говорят мысли материальны и как вы думаете, мечтаете и
                                        мыслите, то так и будет. Как пример многие задают себе вопрос: Почему я не могу
                                        выйграть в лотерею? Но обычно ответ кроется совсем в другой плоскости - а вы,
                                        например, лотерейный билет Гослото покупали вообще? Если нет, то и продолжать
                                        дальше не стоит.</p>
                                    <p className={"description"}>Удача приходит к терпеливым, упертым и немного везучим,
                                        конечно же. Нужно найти свою цель и идти к ней без колебаний. Преодолеть
                                        препятствия и невзгоды. Верить! И она, Госпожа удача, наверняка посетит вас,
                                        даже не сомневайтесь.</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/idti-ne-idti.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Идти не идти"}
                            meta={"Рулетка для ответа на вопрос - идти или не идти. Задайте свой вопрос нашему барабану для получения онлайн ответа"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Вас пригласили на встречу, свидание, мероприятие или на
                                        вечеринку, но непреодолимые обстоятельства заставляют вас думать как поступить.
                                        Вы не можете решиться, неуверенны или боитесь неких препятствий. Не беда. Хватит
                                        себя мучать и <b>гадать идти или не идти.</b>Давайте разберёмся в очевидных
                                        вещах, о которых почему то забывают при принятии окончательного решения.</p>
                                    <ul>
                                        <li>вас не должны принуждать или заставлять</li>
                                        <li>полезно это будет для вас или вредно</li>
                                        <li>вы должны получать удовольствие от этого</li>
                                        <li>вас не должно волновать мнение других, кроме вас пригласившего</li>
                                        <li>вы не должны бросать более важные дела</li>
                                        <li>если обещали, то держите слово</li>
                                        <li>не придумывайте отмазок, говорите правду</li>
                                        <li>это не вопрос жизни и смерти, не обманываете себя</li>
                                        <li>не совершайте поступков, о которых можете пожалеть</li>
                                        <li>если это обязанность, то видимо нужно идти</li>
                                        <li>если надо подготовиться, то вперед</li>
                                        <li>страх? а как лучше будет по результату</li>
                                    </ul>
                                    <p className={"description"}>Для принятия окончательного решения воспользуйтесь
                                        вопросом <b>Да или НЕТ</b> на свой вопрос и сделайте несколько гаданий на нашем
                                        барабане</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/kupit-ne-kupit.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Купить не купить"}
                            meta={"Онлайн гадание - купить не купить. Поможет сделать правильный выбор в непростом решении как поступить в той или иной ситуации"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Увидев ту или иную вещь, которую очень хочется
                                        приобрести, всегда встает дилемма <b>купить или не купить.</b> Это в первую
                                        очередь касается дорогих вещей, таких как новый IPhone, которым хочется
                                        хвастаться перед другими и выглядеть круто. При условии что денег на покупки
                                        бывает ограниченное количество, то нужно сделать выбор между обладанием
                                        определенной вещью или, еще хуже - между двумя. Это тяжелый выбор, в котором
                                        нужна помощь или совет. Для этого и создано данное онлайн гадание на <Link
                                            to="/">да нет</Link>.</p>
                                    <h2>Как сделать правильный выбор</h2>
                                    <p className={"description"}>Все на самом деле просто, оцените трезво свои
                                        финансовые возможности и подтвердите свое решение данной рулеткой. Нажмите на
                                        центр барабана и выждите, выбрав нужную скорость вращения. Ждите результата и
                                        принимайте правильное решение.</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/prodat-ne-prodat.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Продать не продать"}
                            meta={"Решить стоит ли продавать ту или иную вещь поможет онлайн гадание. Задайте свой вопрос - Да Нет, и получите ответ"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Наступило время когда срочно потребовались деньги, но
                                        <a href="http://go.lead-gid.ru/SHEEU" target="_blank"><b>кредит</b></a> брать не
                                        хочется? На продажу есть несколько вариантов, но вам жалко и не знаете как
                                        поступить в данном случае? Соответственно нужно сделать правильный выбор, но
                                        решение проблемы все время откладывается из-за неопределенности.</p>
                                    <p className={"description"}>Помощь в решении данной проблемы, а стоит ли,
                                        постарается решить онлайн гадание на ваш вопрос: <b>продать не продать</b> ту
                                        или иную вещь.</p>
                                    <h2>Крутите барабан</h2>
                                    <p className={"description"}>Конечно сложность выбора очевидна и дабы подвинуть чашу
                                        весов в ту или иную сторону нужно сделать как минимум 10 гаданий и посмотреть
                                        результаты. Нажмите на центр барабана и дождитесь как остановится стрелка.</p>
                                    <p className={"description"}>Удачи.</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/prostit-ne-prostit.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Простить не простить"}
                            meta={"Вас обидели и вы не знаете простить или не простить человека? Воспользуйтесь онлайн гаданием, а так же трезвой головой для принятия решения"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Какой сложный выбор всегда предстает между выбором <b>простить
                                        не простить.</b> Обиды между двумя людьми являются очень тонкой душевной
                                        материей, особенно между молодыми людьми, которые испытывают первые
                                        романтические чувства. Развитие любовных баталий всегда сопровождается вспышками
                                        нежности или отторжения, это настолько переменчивые события, что исчезает все
                                        вокруг и голова перестает работать в полную силу. Как говориться: от ненависти
                                        до любви - один шаг.</p>
                                    <h2>Решение проблемы прощения</h2>
                                    <p className={"description"}>Что бы принимать решение нужно четко взвесить
                                        совершенные поступки, которые послужили перед ссорой. Проанализировать свои
                                        чувства, <b><Link to="/lubit-ne-lubit.html">любит не любит</Link></b>, было это
                                        предательством или маленькой шалостью и так далее. Ну а далее воспользуйтесь
                                        онлайн гаданием на свой вопрос.</p>
                                    <p className={"description"}>Оно можно сказать точно, что не делайте поспешных
                                        кардинальных решений. Подождите некоторое время и остыньте, на холодную голову и
                                        расслабившись, решить <Link to="/">да нет</Link> гораздо правильнее. Все будет
                                        хорошо.</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/delat-ne-delat.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Делать не делать"}
                            meta={"Онлайн рулетка для ответа на вопрос делать или не делать что-то дальше. Возможность утвердиться в своем решении и поступить верно"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>В повседневной жизнь возникают моменты когда ориентация
                                        в пространстве и вектор принятия решений теряются. Человек теряется и как будто
                                        заблудился, не знает куда идет. Все падает из рук и не получается собраться в
                                        полной мере для понимания&nbsp;<b>делать или не делать</b> что то дальше. Ответы
                                        на эти вопросы скрываются в самом человеке, его внутреннем состоянии и
                                        психологическом состоянии. Возможно у вас просто хандра или апатия из-за
                                        окружающей обстановки или определенных событий.</p>
                                    <p className={"description"}>Но коли уж вы решили воспользоваться <Link to="/"><b>рулеткой
                                        для гадания</b></Link> то вот она выше. Нажимайте на центр барабана и получайте
                                        ответы на свои вопросы.</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>
                <Route exact path="/exat-ne-exat.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Ехать не ехать"}
                            meta={"Гадание \"ехать не ехать\". Крутите барабан для получения быстрого ответа на свой вопрос онлайн"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Впереди ожидает дорога или <a
                                        href="https://tp.media/r?marker=93839.danet&amp;p=4114&amp;u=https%3A%2F%2Faviasales.ru"
                                        target="_blank">авиаперелет</a>, но вас одолевают сомнения в целесообразности
                                        данной поездки, ее безопасности или срочности. Так как не можете самостоятельно
                                        четко определиться с решением данной задачи, то воспользуйтесь бесплатным онлайн
                                        гаданием на вопрос <Link to="/">да нет</Link>.</p>
                                    <p className={"description"}>Что бы решить <b>ехать или не ехать </b>вам достаточно
                                        мысленно сосредоточится и <span style={{color: "#ff0000"}}><b>нажать на центр барабана</b></span>.
                                        В зависимости от продолжительности нажатия, начальная скорость вращения будет
                                        рандомной. Далее от этого будет зависеть количество вращений и полученный ответ.
                                        Результат выдается случайным образом, в зависимости от исполненных условий выше.
                                        Все на волю случая, который Вы запустили.</p>
                                    <p className={"description"}>Удачи</p>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>

                <Route exact path="/dast-ne-dast.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <YesNo
                            title={"Даст не даст"}
                            meta={"Онлайн гадание \"Даст не даст\". Быстрые ответы на распостраненные вопросы с возможностью подсчета результата"}
                            description={
                                <React.Fragment>
                                    <p className={"description"}>Для проведения данного гадания доступны различные
                                        варианты событий, которые происходят в нашей жизни. Самыми распространёнными
                                        ситуациями вопроса <b>даст не даст</b>являются:</p>
                                    <ul>
                                        <li>даст ли списать</li>
                                        <li>даст ли денег</li>
                                        <li>даст ли девушка себя поцеловать</li>
                                        <li>даст ли он о себе знать</li>
                                    </ul>
                                </React.Fragment>
                            }
                        />
                    </Suspense>
                )}/>

                <Route exact path="/kontakt.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <StaticPage
                            title={"Контакты"}
                            meta={"Связаться с нами. Сайт Да НЕТ"}
                            description_file={'kontakt'}
                        />
                    </Suspense>
                )}/>
                <Route exact path="/politic.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <StaticPage
                            title={"Политика конфиденциальности"}
                            meta={"Политика конфиденциальности сайта Да НЕТ"}
                            description_file={'politic'}
                        />
                    </Suspense>
                )}/>
                <Route exact path="/download.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <StaticPage
                            title={"Скачать приложение Да НЕТ"}
                            meta={"Скачать мобильное приложение ДА НЕТ на мобильнй телефон"}
                            description_file={'download'}
                        />
                    </Suspense>
                )}/>
                <Route exact path="/download.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <StaticPage
                            title={"Скачать приложение Да НЕТ"}
                            meta={"Скачать мобильное приложение ДА НЕТ на мобильнй телефон"}
                            description_file={'download'}
                        />
                    </Suspense>
                )}/>
                <Route exact path="/kak-igrat.html" render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <StaticPage
                            title={"Как играть"}
                            meta={"Правила игры ДА НЕТ. Описание начала и прохождения, а так же сброса результата"}
                            description_file={'kak-igrat'}
                        />
                    </Suspense>
                )}/>
                {
                    newPage.map((item) => {
                        return <Route exact path={item.link} render={() => (
                            <Suspense fallback={<div>Загрузка...</div>}>
                                <YesNo
                                    title={item.title}
                                    meta={item.meta}
                                    description={
                                        <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                    }
                                />
                            </Suspense>
                        )}/>
                    })
                }
                <Route exact path="/" render={() => <Redirect to={"/index.html"}/>}/>
                <Route render={() => (
                    <Suspense fallback={<div>Загрузка...</div>}>
                        <NotFound/>
                    </Suspense>
                )}/>
            </Switch>
        </div>
        <Footer/>
    </React.Fragment>
};
export default Root;